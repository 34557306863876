











import { Vue, Component, Prop } from 'vue-property-decorator';
import Loading from 'vue-loading-overlay';

import Loader from '@/shared/resources/components/Loader.vue';

@Component({
  components: {
    Loader,
    Loading,
  },
})
export default class LoaderOverlay extends Vue {
  /**
   * Props
   */
  @Prop({ type: Boolean, default: true }) private fullPage!: boolean;
}
